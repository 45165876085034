<template>
  <img
    class="icon"
    :src="require(`@/assets/images/icons/${iconName}.svg`)"
    :alt="alt || 'иконка'"
  />
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
    },
  },
}
</script>
