export default {
  /*
   * Ставим имя файла как название модуля
   */
  name: 'modals',
  namespaced: true,

  /* Ну и далее, обычное оформление  */
  state: {
    isOpen: false,
  },
  mutations: {
    updateStatus(state, val) {
      state.isOpen = val
    },
  },
  getters: {
    getModalStatus(state) {
      return state.isOpen
    },
  },
}
