<template>
  <div>
    <FirstSection class="mb-60" />
    <SecondSection class="mb-60" />
    <ThirdSection class="mb-60" />
    <FourthSection class="mb-60" />
    <FifthSection class="mb-60" />
    <SixthSection class="mb-60" />
    <SeventhSection class="mb-60" />
    <EighthSection class="mb-60" />
    <NinthSection class="mb-60" />
    <!-- <TenthSection class="pb-60" /> -->
    <EleventhSection />
  </div>
</template>

<script>
import FirstSection from '@/components/organisms/Sections/First/index.vue'
// import TenthSection from '@/components/organisms/Sections/Tenth/index.vue'

const SecondSection = () =>
  import('@/components/organisms/Sections/Second/index.vue')
const ThirdSection = () =>
  import('@/components/organisms/Sections/Third/index.vue')
const FourthSection = () =>
  import('@/components/organisms/Sections/Fourth/index.vue')
const FifthSection = () =>
  import('@/components/organisms/Sections/Fifth/index.vue')
const SixthSection = () =>
  import('@/components/organisms/Sections/Sixth/index.vue')
const SeventhSection = () =>
  import('@/components/organisms/Sections/Seventh/index.vue')
const EighthSection = () =>
  import('@/components/organisms/Sections/Eighth/index.vue')
const NinthSection = () =>
  import('@/components/organisms/Sections/Ninth/index.vue')
const EleventhSection = () =>
  import('@/components/organisms/Sections/Eleventh/index.vue')

/**
 * @displayName TMain
 */

export default {
  name: 'TMain',
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    FifthSection,
    SixthSection,
    SeventhSection,
    EighthSection,
    NinthSection,
    // TenthSection,
    EleventhSection,
  },
}
</script>

<style lang="scss" scoped>
.mb-60 {
  margin-bottom: 60px;
  @include mobile {
    margin-bottom: 30px;
  }
}
</style>
