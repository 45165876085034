<template>
  <div class="main">
    <TMain
      :class="[
        'main-inner',
        {
          'main-inner--open-modal': isOpen,
          'main-inner--open-modal-close': closeAnim,
          'main-inner--open-modal-open': openAnim,
        },
      ]"
    />
    <TopButton />
  </div>
</template>

<script>
import TMain from '@/components/templates/index.vue'
import TopButton from '@/components/molecules/BackToTop/index.vue'
/**
 * @displayName Главная страница
 */
export default {
  name: 'Main',
  components: { TMain, TopButton },
  data() {
    return {
      // если повешать класс на checkOpenModal, он отрабатывает раньше, чем js из watch, поэтому есть эта переменная
      isOpen: false,
      isDev: true,
      closeAnim: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty(
        '--scroll-y',
        `${window.scrollY}px`,
      )
    })

    let widgetScript = document.createElement('script')
    widgetScript.setAttribute(
      'src',
      'https://client.callme.kz/widget/5c4d3818-0605-499e-982f-f171d0001e54',
    )
    document.head.appendChild(widgetScript)
  },
  computed: {
    checkOpenModal() {
      return this.$store.getters['Modals/getModalStatus']
    },
  },
  watch: {
    // фиксируем задний фон, при открытии модалки (отключаем прокрутку)
    checkOpenModal: {
      handler(val) {
        if (val) {
          const scrollY = document.documentElement.style.getPropertyValue(
            '--scroll-y',
          )
          const body = document.body
          body.style.position = 'fixed'
          body.style.top = `-${scrollY}`
          this.isOpen = true
          this.openAnim = false
        } else {
          this.closeAnim = true
          setTimeout(() => {
            const body = document.body
            const scrollY = body.style.top
            body.style.position = ''
            body.style.top = ''
            window.scrollTo(0, parseInt(scrollY || '0') * -1)
            this.isOpen = false
            this.closeAnim = false
          }, 500)
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.main {
  position: relative;
  width: 100%;
  height: 100%;

  &-inner {
    position: relative;
    overflow: hidden;
    &--open-modal {
      &::after {
        animation: openAnim linear 0.5s;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 15;
        background-color: rgba(0, 0, 0, 0.8);
      }

      @keyframes openAnim {
        0% {
          background-color: rgba(0, 0, 0, 0);
        }
        100% {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }

      &-close {
        &::after {
          animation: close linear 0.5s;

          @keyframes close {
            0% {
              background-color: rgba(0, 0, 0, 0.8);
            }
            100% {
              background-color: rgba(0, 0, 0, 0);
            }
          }
        }
      }
    }
    @include mobile {
      width: 100vw;
    }
  }
}
</style>
