<template>
  <transition name="fade">
    <a v-if="show" class="back-to-top" href="#hero" v-smooth-scroll>
      <Icons icon-name="arrow-down" class="back-to-top__icon" />
    </a>
  </transition>
</template>

<script>
import Vue from 'vue'
import Icons from '@/components/atoms/Icons/index.vue'
import VueSmoothScroll from 'v-smooth-scroll'

Vue.use(VueSmoothScroll, {
  duration: 500,
})

/**
 * ATOMS/BackToTop
 * @displayName BackToTop
 */
export default {
  name: 'BackToTop',
  components: {
    Icons,
  },
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scroll.bind(this))
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll.bind(this))
  },
  methods: {
    scroll() {
      const st = window.scrollY
      if (st > 250) {
        this.show = true
      } else {
        this.show = false
      }
    },
    // backToTop() {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    //   })
    // },
  },
}
</script>

<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  right: 65px;
  bottom: 65px;
  width: 60px;
  height: 60px;
  appearance: none;
  border: none;
  outline: none;
  border-radius: 50%;
  margin: 0;
  background-color: rgba($c-dark, 0.35);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;

  @include tablet-portrait {
    bottom: 100px;
  }

  @media (max-width: 800px) {
    right: 40px;
    bottom: 140px;
  }

  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba($c-dark, 0.65);
    transform: translateY(-2px);
  }

  &__icon {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    margin: 0 auto;
  }
}
</style>
