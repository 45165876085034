<template>
  <div class="modal">
    <div class="modal__body" v-click-outside="onClickOutside">
      <div class="modal__close" @click="onClickOutside">
        <RFIcon icon-name="x" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import RFIcon from '@/components/atoms/Icons/index.vue'
import vClickOutside from 'v-click-outside'

export default {
  components: { RFIcon },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5000;

  animation: appearance 1.5s;

  @keyframes appearance {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__body {
    position: relative;
    max-width: 1200px;
    height: 700px;
    overflow: hidden;
    background: $white;
    border: 5px solid $white;
    border-radius: 40px;
    margin: 5vh calc((100vw - 1200px) / 2);

    @include tablet-portrait {
      width: calc(100vw - 50px);
      margin: 5vh 25px;
    }

    @include mobile {
      border-radius: 20px;
      height: 480px;
      width: calc(100vw - 30px);
      margin: 10% 15px;
    }
  }
  &__close {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 36px;
    height: 36px;
    background: $gray-700;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 200;

    @include mobile {
      right: 15px;
      top: 20px;
    }
  }
  &__map {
    width: 100%;
    height: 100%;
  }
}
</style>
