<template>
  <component
    @click="$emit('click')"
    :href="href"
    :class="color"
    :target="target"
    :is="currentComponent"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'RFButton',
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: value => {
        return ['primary', 'secondary', 'tab'].includes(value)
      },
    },
    href: {
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  computed: {
    currentComponent() {
      return this.href ? 'a' : 'button'
    },
  },
}
</script>

<style lang="scss" scoped>
button,
a {
  color: $white;
  outline: none;
  border: 1px solid transparent;
  border-radius: 8px;

  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.025em;
  line-height: 1;
  cursor: pointer;

  transition: background-color 0.5s ease-out;

  &:active {
    transform: translateY(1px);
  }

  &.primary {
    padding: 19px 40px;
    font-weight: 600;
    font-size: 20px;
    background-color: $primary-500;
    &:active {
      background-color: $primary-400;
    }
    &:disabled {
      background: $gray-100;
      color: $gray-300;
    }
    &:hover {
      background-color: $primary-300;
    }
  }
  &.secondary {
    padding: 19px 40px;
    border: 1.5px solid $white;
    background: transparent;
    font-weight: 600;
    font-size: 20px;

    &:active {
      background-color: $gray-500;
    }
    &:disabled {
      color: $gray-400;
      background: $gray-600;
    }
    &:hover {
      background-color: $gray-400;
    }
  }
  &.tab {
    border-radius: 52px;
    padding: 8px 12px 8px 10px;
    background-color: $white;
    @include font-styles(14px, 18px, 400, $c-dark);

    &--active,
    &:hover {
      color: $white;
      background-color: $c-main-green;
    }
  }
}
</style>
