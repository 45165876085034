<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <a class="header__logo" href="https://orda-invest.kz/" target="_blank">
          <RFIcon class="header__logo-icon" icon-name="logo" />
        </a>
        <section>
          <a
            class="header__contacts"
            href="https://api.whatsapp.com/send?phone=77073330002"
            target="_blank"
          >
            <RFIcon class="header__phone-icon" icon-name="phone" />
          </a>
          <RFButton
            @click="$emit('open', true)"
            class="header__btn"
            color="secondary"
            >Обратный звонок</RFButton
          >
        </section>
      </div>
    </div>
  </header>
</template>

<script>
import RFIcon from '@/components/atoms/Icons/index.vue'
import RFButton from '@/components/atoms/Buttons/index.vue'

export default {
  components: { RFIcon, RFButton },
}
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  z-index: 2;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    @include mobile {
      align-self: flex-start;
    }

    &-icon {
      @include mobile {
        width: 88px;
        height: 30px;
      }
    }
  }
  &__contacts {
    display: flex;
    align-items: flex-end;

    @include mobile {
      align-items: center;
    }
  }
  section {
    gap: 35px;
    align-items: center;
    @include flex-row;

    @include mobile {
      gap: 10px;
      flex-direction: column;
    }

    > a {
      align-items: center;
      @include flex-row;

      @include mobile {
        order: 2;
      }

      > aside {
        justify-content: center;
        align-items: flex-end;
        @include flex-column;
      }
    }
  }
  &__phone {
    @include font-styles(22px, 22px, 700);

    @include mobile {
      @include font-styles(16px, 18px, 500);
    }
  }
  &__substring {
    @include font-styles(14px, 14px, 600);

    @include mobile {
      @include font-styles(10px, 10px, 500);
    }
  }
  &__phone-icon {
    margin-left: 10px;
    width: 40px;
    height: 40px;

    @include mobile {
      width: 22px;
      height: 22px;
    }
  }

  &__btn.secondary {
    padding: 10px 15px;
    @include font-styles(16px, 22px, 500);

    @include mobile {
      @include font-styles(14px, 16px, 500);
      order: 1;
    }
  }
}
</style>
