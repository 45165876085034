<template>
  <div>
    <Header @open="isShowCallbackModal = true" />
    <RFModal v-if="isShowModal" @close="isShowModal = !isShowModal">
      <yandex-map
        class="modal__map"
        :coords="yandexCoords"
        :controls="controls"
      >
        <ymap-marker
          marker-id="123"
          :coords="yandexCoords"
          :icon="markerIcon"
        />
      </yandex-map>
    </RFModal>
    <section class="hero" id="hero">
      <div class="container">
        <div class="hero__content p-15">
          <RFIcon class="hero__title" icon-name="title" />

          <div class="hero__btns">
            <a href="#flats" class="hero__btn-choose" v-smooth-scroll
              >Выбрать квартиру</a
            >

            <RFButton
              href="/booklet.pdf"
              target="_blank"
              class="hero__btn-download"
              color="secondary"
            >
              <RFIcon icon-name="arrow-down" />

              <span>Посмотреть буклет</span>
            </RFButton>
          </div>
          <div class="hero__indication p-15">
            <p class="hero__indication-description" @click="isShowModal = true">
              Шымкент, жилой массив Нурсат
              <RFIcon icon-name="arrow-up-right" />
            </p>
          </div>
        </div>
        <div class="hero-bottom-wrapper">
          <div class="hero__blocks p-15">
            <div
              class="hero__block"
              v-for="(block, index) in blocks"
              :key="index"
            >
              <RFIcon class="hero__block-icon" :icon-name="block.icon" />
              <p class="hero__block-description" v-html="block.label" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <CallbackModal
      v-if="isShowCallbackModal"
      @close="isShowCallbackModal = !isShowCallbackModal"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import RFButton from '@/components/atoms/Buttons/index.vue'
import RFIcon from '@/components/atoms/Icons/index.vue'
import Header from '@/components/molecules/Header.vue'
import RFModal from '@/components/atoms/Modals/RFModal.vue'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import VueSmoothScroll from 'v-smooth-scroll'
const CallbackModal = () =>
  import('@/components/atoms/Modals/CallbackModal.vue')

Vue.use(VueSmoothScroll, {
  duration: 3000,
})

export default {
  components: {
    RFButton,
    RFIcon,
    Header,
    RFModal,
    yandexMap,
    ymapMarker,
    CallbackModal,
  },
  data() {
    return {
      yandexCoords: [42.363059, 69.65248],
      isShowModal: false,
      isShowCallbackModal: false,
      controls: ['zoomControl'],
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: require('@/assets/images/icons/map-picker.svg'),
        imageSize: [190, 68],
        imageOffset: [0, 0],
        content: '',
        contentOffset: [0, 15],
        contentLayout:
          '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>',
      },
      blocks: [
        { icon: 'clock', label: `Сдача <br /> III квартал 2024` },
        { icon: 'diamond', label: `Комфорт <br /> класс` },
        { icon: 'money', label: `Цена от <br /> 16 млн  ₸` },
        { icon: 'arrow-up-height', label: `Высота потолков <br /> 3 метра` },
        {
          icon: 'square',
          label: `Площадь квартир <br /> от 46,25 до 124,3 м<sup>2</sup>`,
        },
      ],
    }
  },
  watch: {
    isShowModal: {
      handler(val) {
        this.$store.commit('Modals/updateStatus', val)
      },
    },
    isShowCallbackModal: {
      handler(val) {
        this.$store.commit('Modals/updateStatus', val)
      },
    },
  },
  methods: {
    setNewYandexCoords(e) {
      this.yandexCoords = e.get('coords')
    },
  },
}
</script>

<style lang="scss" scoped>
// * Из за нижней подложки, которую невозможно высчитать в адаптиве
.container {
  @include mobile {
    padding: 0;
  }
}
.p-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.hero {
  position: relative;
  background: url('../../../../assets/images/first/bg-hero.png') center center /
    cover no-repeat;
  height: 100vh;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  z-index: 1;
  justify-content: flex-end;
  @include flex-column();

  @include mobile {
    background: url('../../../../assets/images/first/first-mobile.png') center
      center / cover no-repeat;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -gradient(
      193.16deg,
      rgba(35, 37, 43, 0) 45.82%,
      #23252b 97.03%
    );
    opacity: 0.78;
    z-index: -1;
  }
  &__content {
    color: $white;
    animation: manifestation1 1.5s ease-in;
  }
  &__title {
    margin-bottom: 30px;
    @include tablet-portrait {
      margin: 0 auto 30px;
    }
    @include mobile-landscape {
      display: block;
    }
    @include mobile {
      width: 260px;
      height: 72px;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    color: $white;

    @include tablet-portrait {
      justify-content: center;
    }
    @include mobile-landscape {
      flex-direction: column;
    }
  }
  &__btn-choose {
    display: inline-flex;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    width: 250px;
    height: 60px;
    background: $c-main-green;
    border-radius: 8px;
    text-decoration: none;
    @include font-styles(20px, 22px, 600);

    @include mobile-landscape {
      margin-right: 0;
      margin-bottom: 10px;
    }
    @include mobile {
      width: 100%;
    }
  }
  &__btn-download {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 8px;
    }
    @include mobile {
      width: 100%;
    }
  }
  &-bottom-wrapper {
    position: relative;
    padding: 43px 0 50px;

    @include mobile-small {
      padding: 20px 0 30px;
    }

    @include mobile {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        height: 110%;
        background: -gradient(180deg, rgba(58, 55, 55, 0.4) 0.1%, #3a3737 15%);
      }
    }
  }
  &__indication {
    display: flex;
    align-items: center;
    margin: 30px 0;

    @include tablet-portrait {
      justify-content: center;
    }
  }
  &__indication-description {
    @include font-styles(22px, 30px, 600);
    font-size: 22px;
    cursor: pointer;
    position: relative;
    margin-right: 4px;

    @include mobile {
      text-align: center;
      max-width: 200px;
      margin-right: 2px;

      @include font-styles(16px, 20px, 400);
    }

    .icon {
      display: inline-flex;
      vertical-align: middle;

      @include mobile {
        width: 17px;
        height: 17px;
      }
    }
  }
  &__blocks {
    display: grid;
    grid-template-columns: repeat(5, 218px);
    justify-content: flex-start;
    overflow-x: scroll;
    height: 52px;
    gap: 30px;

    &::-webkit-scrollbar {
      display: none;
    }
    // for firefox
    scrollbar-width: none;

    @include mobile {
      gap: 20px;
      grid-template-columns: repeat(5, calc((100vw - 30px) / 2));
      justify-content: flex-start;
      overflow-x: scroll;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    color: $white;

    &:nth-child(1) {
      animation: manifestation1 2s ease-in;
    }
    &:nth-child(2) {
      animation: manifestation1 2.2s ease-in;
    }

    &:nth-child(3) {
      animation: manifestation1 2.4s ease-in;
    }

    &:nth-child(4) {
      animation: manifestation1 2.6s ease-in;
    }

    &:last-of-type {
      animation: manifestation1 2.8s ease-in;

      @include mobile {
        min-width: 210px;
      }
    }
  }
  &__block-icon {
    margin-right: 10px;
  }
  &__block-description {
    @include font-styles(16px, 20px, 600);
    width: 100%;

    @include mobile {
      @include font-styles(15px, 17px, 400);
    }
  }
}
</style>
