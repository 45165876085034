import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics'
import VueFacebookPixel from 'vue-facebook-pixel'
import 'wowjs/css/libs/animate.css'

Vue.config.productionTip = false

Vue.use(VueFacebookPixel)

Vue.use(VueAnalytics, {
  id: 'G-52BN4FTGBH',
  router,
  debug: {
    enabled: true,
  },
})

/**
 * Если появилась необходимость использовать фреймворк
 * лучше создать директорию plugins/*
 * настраивать библиотеку там, затем импортировать и подключать его здесь
 */

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
